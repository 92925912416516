



.text_p{
font-size:16px;
letter-spacing: 0px;

    letter-spacing: 0px;
color: #827F7F;
opacity: 1;
}
.bg_custom{
    background: #F8F8F8 0% 0% no-repeat padding-box;
border-radius: 6px;
}

ul.check-box-listing li span{
        flex: 0 0 96%;
}
.sm_text
{

    color: #827F7F;
opacity: 1;
}

.date_picker{
    position: relative;
}

.btn-custom{
    background-color: #C8102E;
    color: white;
}
.btn-custom:hover{
    background-color: #C8102E;
    color: white;
}
#About ul {
   list-style: none;
   margin-left: -10px;
   position: relative;
}
#About ul li:before {
    content: "•";
    font-size: 37px;
    padding-right: 17px;
    position: absolute;
    left: 19px;
    color: #000000;
}
#book label{
    color: #000000;
}
.w-40{
    width: 50%;
}
.img_text{
    /*font: normal normal normal 18px/22px Product Sans;*/
letter-spacing: 0px;
color: #928F8F;
opacity: 1;
}
.phone{
    width: 20px;
height: auto;
}
#sales-team .form-group{
    position: relative;
}
#sales-team input{
    padding-left: 25px;
}
#sales-team img{
    position: absolute;
    top: 40px;
    left: 19px;
    width: 20px;
}
#sales-team > .form-control{
    padding-left: 46px;
    height: 48px;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
    color: #827F7F;
}
/*.forms_img{
     position: absolute;
    top: 45px;
    left: 19px;
    width: 17px;
    height: 20px;
}*/
.contact_img{
    height: 21px;
    /*width: 20px;*/
}
#sales-team img {
    position: absolute;
    top: 46px;
    left: 19px;
    width: 16px;
    height: auto;
}
#sales-team img.img1 {
    position: absolute;
    top: 46px;
    left: 19px;
    width: 19px;
    height: auto;
}a
#sales-team img.img2 {
  position: absolute;
  top: 48px;
  left: 19px;
  width: 21px;
  height: auto;
}
#sales-team img.img3 {
    position: absolute;
top: 43px;
    left: 19px;
    width: 15px;
    height: auto;
}
.email_img{
    position: absolute;
    top: 20;
    /*top: 10px;*/
    height: 20px;
}
.job_img{
    height: 18px;
}
#width-50 .col-lg-2{
        flex: 0 0 21.666667%;
    max-width: 20.666667%;
}
.btn_q{
    margin-top: 22px;
    margin-left: 30px;
}
@media only screen and (max-width:992px) {
    
  .btn_q {
   
width: 100%;
  margin: 0px 0px;
  max-width: 180px;
  }
 
.border-bottoma{
        border-bottom: 1px solid rgba(0,0,0,.1);
}
#width-50 .col-lg-2{
        flex: 0 0 50% !important;
    max-width: 50% !important;
}
}
@media only screen and (max-width:768px) {
    
  .btn_q {
   
width: 100%;
  margin: 0px 0px;
  }
  #width-50 .col-lg-2{
        flex: 0 0 100% !important;
    max-width: 100% !important;
}
} 

.btn_border{
    border-bottom: 1px solid #c8102e;
    padding-bottom: 13px;
    max-width: 185px;
}
.f-20{
    font-size: 20px;
}
.ul.rental-steps-wrap{

    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    list-style: none;
    -ms-flex-wrap: wrap;
    /* flex-wrap: wrap; */
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
    padding: 20px 0 15px;
    margin: 0;
    }
    label.custom-control-label{
    color: #827F7F !important;
    font-size: 12px;
}
.gj-icon{
    display: none;
}
/*.gj-icon::before{
    content: "s";
    position: absolute;
    z-index: 1
}*/
.calender img{
        position: absolute;
    z-index: 1;
    right: 10px;
    top: 9px;
    width: 18px;
}
#sales-team .img2{
    position: absolute;
    top: 48px;
    left: 19px;
    width: 21px;
    height: auto;
}